import { ContactCard20Regular, Search16Regular } from "@fluentui/react-icons";
import {
  Button,
  ComponentStyleFunctionParam,
  Flex,
  FormDropdown,
  Table,
} from "@fluentui/react-northstar";
import { dialog } from "@microsoft/teams-js";
import { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getDevice } from "../../utils/device.utils";
import Styles from "./EventAttendees.module.scss";
import {
  dontClose,
  getDropdownItems,
  getRows,
  handleClear,
  handleOnChange,
  handleSearchQueryChange,
} from "./EventAttendeesUtils";

function EventAttendees(data: any) {
  const urlInfo = useCallback(
    () =>
      JSON.parse(
        decodeURIComponent(
          // eslint-disable-next-line react/destructuring-assignment
          data?.location?.search?.replace("?attendeesInfo=", "")
        )
      ),
    [data]
  );
  const { t } = useTranslation();
  const isMobile = ["mobile", "tablet"].includes(getDevice());
  const [query, setQuery] = useState("");
  const [updatedAttendeesObject, setUpdatedAttendeesObject] = useState(
    urlInfo()
  );
  const initialAttendeesObject = useRef(urlInfo());
  const ICON_STYLE = (theme: ComponentStyleFunctionParam) => ({
    color: theme.theme.siteVariables
      ? theme.theme.siteVariables?.segmentIcons?.icons
      : "",
  });
  const ICON_STYLE_DISABLED = (theme: ComponentStyleFunctionParam) => ({
    color: theme.theme.siteVariables
      ? theme.theme.siteVariables?.colorScheme?.default?.foregroundDisabled
      : "",
  });

  return (
    <Flex
      column
      data-testid="event-attendees"
      className={Styles.taskmoduleContent}
      styles={(theme) => ({
        backgroundColor:
          theme.theme.siteVariables.colorScheme?.default.background,
        height: "100%",
        flexDirection: "column",
        justifyContent: "space-between",
      })}
    >
      <FormDropdown
        noResultsMessage={t("searchContacts.noResultsMessage")}
        items={getDropdownItems(updatedAttendeesObject, query)}
        search={(items) => items}
        fluid
        clearable
        placeholder={t("events.eventAttendees.searchBar")}
        styles={{
          paddingTop: "20px",
          paddingBottom: "10px",
        }}
        data-testid="trip-logistic-event-attendees-dropdown"
        onChange={(e, param) =>
          handleOnChange(
            e,
            param,
            setQuery,
            setUpdatedAttendeesObject,
            initialAttendeesObject
          )
        }
        onSearchQueryChange={(e, param) =>
          handleSearchQueryChange(e, param, setQuery, () =>
            handleClear(
              setQuery,
              setUpdatedAttendeesObject,
              initialAttendeesObject
            )
          )
        }
        toggleIndicator=""
        onKeyDown={dontClose}
        searchInput={
          query === ""
            ? {
                icon: <Search16Regular />,
                iconPosition: "start",
              }
            : undefined
        }
      />
      <Flex
        column
        styles={{
          overflow: "auto",
        }}
      >
        <Table
          header={{
            items: [
              {
                content: (
                  <Flex
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <ContactCard20Regular style={{ marginRight: "8px" }} />{" "}
                    {t("events.eventAttendees.name")}
                  </Flex>
                ),
                key: "name-header",
              },
              {
                content: t("events.eventAttendees.tripDates"),
                key: "trip-dates-header",
              },
              {
                content: t("events.eventAttendees.sharedBookings"),
                key: "bookings-header",
              },
            ],
            className: Styles.tableColumns,
          }}
          rows={getRows(
            updatedAttendeesObject,
            ICON_STYLE,
            ICON_STYLE_DISABLED,
            t
          )}
        />
      </Flex>
      <Flex
        hAlign="end"
        className={Styles.buttons}
        styles={(theme) => ({
          borderTop: isMobile ? "none" : "1px solid",
          borderColor:
            theme.theme?.siteVariables?.colorScheme?.default?.border2,
          marginTop: "auto",
          height: "91px",
        })}
      >
        <Button
          content={t("closeCollaborators.editor.closeButton")}
          flat
          onClick={() => dialog.url.submit("close")}
          data-testid="close-collaborators-dialog-cancel-button"
          tinted={isMobile}
          styles={() => ({
            marginTop: "24px",
            marginBottom: "32px",
          })}
        />
      </Flex>
    </Flex>
  );
}

export default EventAttendees;
