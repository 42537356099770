/* eslint-disable react/react-in-jsx-scope */
import { telemetryService } from "@amadeus-cytric/cytric-teams-react-common-library";
import { AddIcon, Button, Text } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import RedirectionTypeEnum from "../../../common/models/redirection/RedirectionTypeEnum";
import { useAppDispatch, useAppSelector } from "../../../store/StoreHooks";
import { breadcrumbActions } from "../../../store/breadcrumb/breadcrumb-slice";
import { selectFeatureToggle } from "../../../store/feature-toggles/FeatureTogglesSelector";
import { FeatureToggleDefinition } from "../../../utils/constants";
import NO_LABEL_NO_URL from "../../personal-tab/breadcrumb/constants";
import {
  redirectToCreateExpenseStatement,
  redirectToExpenseDashboard,
} from "../redirection/ExpenseRedirectionHelper";
import styles from "./DashboardHeader.module.scss";

function DashboardHeader() {
  const { t } = useTranslation("translation", {
    keyPrefix: "expenses-dashboard",
  });
  const dispatch = useAppDispatch();
  const history = useHistory();

  const improvedFlowToggle = useAppSelector((state: any) =>
    selectFeatureToggle(state, FeatureToggleDefinition.improvedExpenseFlow.id)
  );

  const viewExpensesHandler = () => {
    telemetryService.trackEvent({
      name: "expenses-dashboard-viewExpenses",
    });

    if (improvedFlowToggle?.isActive) {
      dispatch(
        breadcrumbActions.setExpenseLevel2({
          label: t("yourExpenses"),
          url: "",
        })
      );
      dispatch(breadcrumbActions.setExpenseLevel3(NO_LABEL_NO_URL));
      history.push(
        `/classic?segmentType=${RedirectionTypeEnum.EXPENSE_DASHBOARD}`
      );
    } else {
      redirectToExpenseDashboard();
    }
  };

  const newExpenseStatmentHandler = () => {
    telemetryService.trackEvent({
      name: "expenses-dashboard-createExpense",
    });

    if (improvedFlowToggle?.isActive) {
      dispatch(
        breadcrumbActions.setExpenseLevel2({
          label: t("yourExpenses"),
          url: `/classic?segmentType=${RedirectionTypeEnum.EXPENSE_DASHBOARD}`,
        })
      );
      dispatch(
        breadcrumbActions.setExpenseLevel3({
          label: t("header.newExpense"),
          url: "",
        })
      );
      history.push(
        `/classic?segmentType=${RedirectionTypeEnum.EXPENSE_CREATE}`
      );
    } else {
      redirectToCreateExpenseStatement();
    }
  };

  return (
    <div aria-label="Expense dashboard" className={styles.header}>
      <Text
        as="h2"
        className={styles.header__heading}
        styles={(theme) => ({
          color: theme.theme.siteVariables.colorScheme?.default.foreground,
        })}
      >
        {t("header.headerTitle")}
      </Text>
      <nav
        className={styles["header__button-container"]}
        id="expense-navigation"
        aria-label="Expenses management"
      >
        <Button
          id="header__button--view-expenses"
          data-testid="header__button--view-expenses"
          className={styles["header__button--view-expenses"]}
          content={t("header.viewExpenses")}
          onClick={() => {
            viewExpensesHandler();
          }}
          secondary
        />
        <Button
          id="header__button--new-expense"
          data-testid="header__button--new-expense"
          className={styles["header__button--new-expense"]}
          icon={<AddIcon />}
          onClick={() => {
            newExpenseStatmentHandler();
          }}
          content={t("header.newExpense")}
          primary
        />
      </nav>
    </div>
  );
}

export default DashboardHeader;
