/* eslint-disable import/prefer-default-export */

/**
 * Decodes the given subEntityId even if it contains special characters.
 *
 * Note: This is a workaround for unicode issue:
 * https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem
 *
 * @param subEntityId - The subEntityId to decode.
 * @returns The decoded subEntityId.
 */
function decodeEntity(subEntityId: string) {
  const binaryString = atob(subEntityId);
  const characterArray = Uint8Array.from(binaryString, (c) => c.charCodeAt(0));
  return new TextDecoder().decode(characterArray);
}

/**
 * Decodes the given subEntityId if it is shared from Cytric Classic.
 * All urls coming from Cytric Classic are encoded in base64.
 *
 * @param subEntityId - The subEntityId to decode.
 * @returns The decoded subEntityId.
 */
export function decodeIfSharedFromCytric(
  subEntityId: string | undefined
): string {
  if (subEntityId == null) {
    return "";
  }
  try {
    return subEntityId ? decodeEntity(subEntityId) : subEntityId;
  } catch {
    // If subEntityId is not encoded in base64, return it as is.
    // It is most likely a link from other Cytric Easy feature, not from Cytric Classic.
    return subEntityId;
  }
}
