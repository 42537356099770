import { TeamsUserCredential } from "@amadeus-cytric/cytric-teams-react-common-library";
import {
  RadioButton20Filled,
  RadioButton20Regular,
  Star20Filled,
} from "@fluentui/react-icons";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  ChevronEndIcon,
  ChevronStartIcon,
  CloseIcon,
  Flex,
  Label,
  List,
  Loader,
  Text,
} from "@fluentui/react-northstar";
import { debounce } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Traveler, { TravelerDictionary } from "../../../common/models/Traveler";
import TravelersService, {
  TravelerServicePostBody,
  TravelersServiceResponse,
} from "../../../services/travelers/TravelersService";
import CheckFeatureToggle from "../../../utils/CheckFeatureToggle";
import { FeatureToggleDefinition } from "../../../utils/constants";
import HostSettings from "../../../utils/host.settings";
import Styles from "../styles/travelers-list.module.scss";
import SearchInput from "./SearchInput";

// determined as 20 due to MS graph batch request limit
const INDEX_INCREMENT = 20;
// sonar fix
const shareJoinTextMajorClassName = "text-major";
const shareJoinTextMinorClassName = "text-minor";

interface TravelersListProps {
  subEntityId: any;
  searchButton: (segmentType: string, isDisabled: boolean) => void;
  selectedUser: Traveler | undefined;
  setClickedUser: (clickedUser: Traveler | undefined) => void;
  isMobileOrTablet: boolean;
  setError: (error: any) => void;
}

function TravelersList({
  subEntityId,
  searchButton,
  selectedUser,
  setClickedUser,
  isMobileOrTablet,
  setError,
}: TravelersListProps) {
  const [inputValue, setInputValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [travelersData, setTravelersData] = useState<Traveler[]>();
  const [travelersDictionary, setTravelersDictionary] =
    useState<TravelerDictionary>();
  const [travelersList, setTravelersList] = useState<any>();
  const [searchFavorite, setSearchFavorite] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const lastRequestIdRef = useRef(0);
  const [initialMobileViewportHeight, setInitialMobileViewportHeight] =
    useState<number>(0);
  const [isMobileKeyboardVisible, setIsMobileKeyboardVisible] = useState(false);

  const [totalTravelersCount, setTotalTravelersCount] = useState<number>(0);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const { t } = useTranslation();

  const taJoinATripPaginationActivated = CheckFeatureToggle(
    FeatureToggleDefinition.travelArrangerShareJoinPagination.id
  );

  const debounceInputChange = useMemo(
    () => debounce((value) => setSearchValue(value), 700),
    []
  );

  const handleInputChange = (event: any) => {
    if (event.target && event.target.value != null) {
      setInputValue(event.target.value);
      debounceInputChange(event.target.value);
    }
  };

  const getTravelersData = async () => {
    // Increment and store the current request ID,so that we can
    // ignore outdated responses caused by user input
    lastRequestIdRef.current += 1;
    const currentRequestId = lastRequestIdRef.current;
    const credential = new TeamsUserCredential();
    const graphToken = await credential.getToken("User.Read");
    setIsLoaded(false);

    if (!graphToken?.token) {
      throw new Error("Graph Token can not be retrieved.");
    }

    // we don't have to send count parameter as it is based
    // on MS Graph limit and is set on BE regardless
    const body: TravelerServicePostBody = {};
    if (searchValue) {
      body.name = searchValue;
    }
    if (searchFavorite) {
      body.favorites = searchFavorite;
    }
    body.start = (currentPageNumber - 1) * INDEX_INCREMENT;

    const response = await TravelersService.getTravelers(
      graphToken.token,
      body
    );

    // Check if this is the response of the last request made
    if (currentRequestId === lastRequestIdRef.current) {
      return response?.data as TravelersServiceResponse;
    }
    // If this is not the last request, ignore the response and
    // return undefined, that is handled in the useEffect,
    // to avoid displaying empty list of error component
    return undefined;
  };

  function populateDictionaryAndTravelers(data: TravelersServiceResponse) {
    if (data.dictionaries) {
      // handling cases when travelersDictionary/travelersData is already populated/empty
      if (travelersDictionary) {
        setTravelersDictionary({
          ...travelersDictionary,
          location: {
            ...travelersDictionary.location,
            ...data.dictionaries.location,
          },
        });
      } else {
        setTravelersDictionary(data.dictionaries);
      }
    }
    if (!isInitialLoad && travelersData) {
      setTravelersData([...travelersData, ...data.data]);
    } else {
      setTravelersData(data.data);
    }
  }

  const handleTravelersRetrieval = () => {
    getTravelersData()
      .then((data) => {
        if (data !== undefined) {
          if (data.totalCount) {
            setTotalTravelersCount(data.totalCount);
          }
          populateDictionaryAndTravelers(data);
          setError(null);
          setIsLoaded(true);
        }
      })
      .catch((err) => {
        setError(err);
      });
  };

  const getPhotoString = (graphPhoto: string): string =>
    graphPhoto.at(0) === '"'
      ? graphPhoto.substring(1, graphPhoto.length - 1)
      : graphPhoto;

  const getSelectedUserLabel = (user: Traveler) => {
    const userName = user.displayName
      ? user.displayName
      : user.email.split("@")[0];
    const userPhoto = user.photo
      ? `data:image/jpeg;base64,${getPhotoString(user.photo)}`
      : `${HostSettings.getCDNIcons}default_avatar.png`;
    return (
      <Flex column gap="gap.small">
        <Text
          content={t("shareJoin.bookingForLabel")}
          styles={(theme) => ({
            color: theme.theme.siteVariables.colorScheme?.default.foreground2,
          })}
          className={[
            Styles[shareJoinTextMinorClassName],
            Styles["booking-for-label"],
            isMobileOrTablet && Styles.mobile,
          ].join(" ")}
        />
        <Label
          className={[
            Styles["selected-traveler-label"],
            isMobileOrTablet && Styles.mobile,
          ].join(" ")}
          content={
            <Text
              content={userName}
              styles={(theme) => ({
                color:
                  theme.theme.siteVariables.colorScheme?.default.foreground1,
              })}
              className={[
                Styles["selected-traveler-name"],
                isMobileOrTablet && Styles.mobile,
              ].join(" ")}
            />
          }
          circular
          image={{
            src: userPhoto,
            avatar: true,
            styles: {
              height: "inherit",
              width: "inherit",
              // iphone fix
              maxWidth: isMobileOrTablet ? "24px" : "32px",
            },
          }}
          icon={
            <CloseIcon
              onClick={() => setClickedUser(undefined)}
              styles={(theme) => ({
                color:
                  theme.theme.siteVariables.colorScheme?.default.foreground1,
              })}
            />
          }
          data-testid="selected-traveler-label"
          aria-label="selected traveler label"
          styles={(theme) => ({
            backgroundColor:
              theme.theme.siteVariables.colorScheme?.default.background2,
          })}
        />
      </Flex>
    );
  };

  const getItemHeader = (
    colleague: Traveler,
    loc: any,
    isSelectedItem: boolean
  ) => (
    <Flex styles={() => ({ height: "50px" })}>
      {isSelectedItem ? (
        <Box
          styles={(theme) => ({
            color:
              theme.theme.siteVariables.colorScheme?.brand.foregroundActive,
          })}
        >
          <RadioButton20Filled
            className={Styles["traveler-radio-button"]}
            data-testid={`checked-radio-button-${colleague.userName}`}
            aria-label={`checked radio button for selected user ${colleague.userName}`}
          />
        </Box>
      ) : (
        <RadioButton20Regular
          className={Styles["traveler-radio-button"]}
          data-testid={`unchecked-radio-button-${colleague.userName}`}
          aria-label={`unchecked radio button for non-selected user ${colleague.userName}`}
        />
      )}
      <Avatar
        className={[
          Styles["traveler-image"],
          isMobileOrTablet && Styles.mobile,
        ].join(" ")}
        image={
          colleague.photo
            ? `data:image/jpeg;base64,${getPhotoString(colleague.photo)}`
            : `${HostSettings.getCDNIcons}default_avatar.png`
        }
        data-testid="traveler-avatar"
      />
      <Flex column={isMobileOrTablet}>
        <Flex column className={[Styles["traveler-name"]].join(" ")}>
          <Text
            className={[
              isMobileOrTablet && Styles.mobile,
              Styles[shareJoinTextMajorClassName],
            ].join(" ")}
            content={
              colleague.displayName
                ? colleague.displayName
                : colleague.email.split("@")[0]
            }
            data-testid="traveler-name"
          />
          <Text
            className={[Styles[shareJoinTextMinorClassName]].join(" ")}
            content={colleague.userName}
            styles={(theme) => ({
              color: theme.theme.siteVariables.colorScheme?.default.foreground2,
              paddingBottom: isMobileOrTablet ? "2px" : "0",
              textOverflow: "ellipsis",
            })}
            data-testid="traveler-username"
          />
        </Flex>
        <Text
          className={[
            Styles["traveler-location"],
            isMobileOrTablet && Styles.mobile,
            isMobileOrTablet
              ? Styles[shareJoinTextMinorClassName]
              : Styles[shareJoinTextMajorClassName],
          ].join(" ")}
          content={loc[colleague.location_id].name}
          data-testid="traveler-location"
          styles={(theme) =>
            isMobileOrTablet
              ? {
                  color:
                    theme.theme.siteVariables.colorScheme?.default.foreground2,
                }
              : {
                  color:
                    theme.theme.siteVariables.colorScheme?.default.foreground,
                }
          }
        />
      </Flex>
      {colleague.isFavorite ? (
        <Flex
          styles={(theme) => ({
            color: theme.theme.siteVariables.colorScheme?.yellow.background,
          })}
        >
          <Star20Filled
            className={[
              Styles["traveler-favorite-icon"],
              isMobileOrTablet && Styles.mobile,
            ].join(" ")}
            data-testid="traveler-favorite-icon"
            aria-label="traveler favorite icon"
          />
        </Flex>
      ) : (
        <Flex
          styles={(theme) => ({
            color:
              theme.theme.siteVariables.colorScheme?.default.foregroundDisabled,
          })}
        >
          <Star20Filled
            className={[
              Styles["traveler-favorite-icon"],
              isMobileOrTablet && Styles.mobile,
            ].join(" ")}
            data-testid="traveler-not-favorite-icon"
            aria-label="traveler not favorite icon"
          />
        </Flex>
      )}
    </Flex>
  );

  function getFormattedTravelersList(travelersPerPage: Traveler[]) {
    return travelersPerPage.map((colleague: any, idx: number) => {
      const username = colleague.userName;
      return (
        <>
          <List.Item
            index={idx}
            key={`traveler-item-${username}`}
            aria-label={colleague.displayName}
            selectable
            selected={false}
            styles={{
              paddingLeft: 0,
              paddingRight: 0,
            }}
            className={[
              Styles["traveler-list-item"],
              isMobileOrTablet && Styles.mobile,
            ].join(" ")}
            header={getItemHeader(
              colleague,
              travelersDictionary?.location,
              selectedUser?.userName === colleague.userName
            )}
            onClick={() => setClickedUser(colleague)}
            data-testid={`traveler-item-test-${colleague.username}`}
          />
          <Box
            className={Styles["list-divider"]}
            styles={(theme) => ({
              color: theme.theme.siteVariables.colorScheme?.default.border3,
            })}
          />
        </>
      );
    });
  }

  const paginationFooter = () => {
    const lastPageNumber = Math.ceil(totalTravelersCount / INDEX_INCREMENT);
    return (
      <Flex
        className={[
          Styles["pagination-component"],
          isMobileOrTablet && Styles.mobile,
          isMobileKeyboardVisible && Styles["mobile-keyboard-visible"],
        ].join(" ")}
        data-testid="pagination-component"
        styles={(theme) => ({
          backgroundColor:
            theme.theme.siteVariables.colorScheme?.default.background,
        })}
      >
        <Button
          icon={<ChevronStartIcon size="small" />}
          disabled={currentPageNumber === 1}
          onClick={() => setCurrentPageNumber((pageNumber) => pageNumber - 1)}
          iconOnly
          text
          data-testid="pagination-decrease-button"
          size="small"
        />
        <Text
          content={`${t(
            "shareJoin.pagination.showing"
          )} ${currentPageNumber} ${t(
            "shareJoin.pagination.of"
          )} ${lastPageNumber}`}
        />
        <Button
          icon={<ChevronEndIcon size="small" />}
          disabled={currentPageNumber === lastPageNumber}
          onClick={() => setCurrentPageNumber((pageNumber) => pageNumber + 1)}
          iconOnly
          text
          data-testid="pagination-increase-button"
          size="small"
        />
      </Flex>
    );
  };

  function viewportHandler() {
    // if keyboard on mobile device is opened, we put the pagination component
    // at the bottom of traveler's list to prevent it from being shown on top of
    // the keyboard, so it doesn't reduce the list too much
    const viewport = window.visualViewport;
    if (viewport && initialMobileViewportHeight !== viewport.height) {
      setIsMobileKeyboardVisible(true);
    } else {
      setIsMobileKeyboardVisible(false);
    }
  }

  useEffect(() => {
    if (isMobileOrTablet && window.visualViewport) {
      setInitialMobileViewportHeight(window.visualViewport.height);
    }
  }, [isMobileOrTablet]);

  useEffect(() => {
    if (isMobileOrTablet) {
      const currentWindowViewport = window.visualViewport;
      if (currentWindowViewport) {
        currentWindowViewport.addEventListener("resize", viewportHandler);

        return () => {
          currentWindowViewport.removeEventListener("resize", viewportHandler);
        };
      }
    }

    // fix fot consistent return rule
    return undefined;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobileKeyboardVisible]);

  useEffect(() => {
    // resetting pagination states when search input or favorit toggle changes
    setCurrentPageNumber(1);
    setTotalTravelersCount(0);
    setIsInitialLoad(true);
    setTravelersData([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFavorite, searchValue]);

  useEffect(() => {
    if (isInitialLoad) {
      handleTravelersRetrieval();
      setIsInitialLoad(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialLoad]);

  useEffect(() => {
    // request travelers only when you are going to the next page
    if (
      !isInitialLoad &&
      currentPageNumber >
        Math.ceil((travelersData?.length ?? 0) / INDEX_INCREMENT)
    ) {
      handleTravelersRetrieval();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageNumber]);

  useEffect(() => {
    const travelersPerPageStartIdx = (currentPageNumber - 1) * INDEX_INCREMENT;
    if (
      travelersData &&
      travelersData.length > travelersPerPageStartIdx &&
      travelersDictionary
    ) {
      // getting travelers data from state cache based on current page
      const travelersPerPage = travelersData.slice(
        travelersPerPageStartIdx,
        currentPageNumber * INDEX_INCREMENT
      );
      const formattedTravelersList =
        getFormattedTravelersList(travelersPerPage);
      setTravelersList(formattedTravelersList);
    } else {
      setTravelersList(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [travelersData, selectedUser, currentPageNumber, searchFavorite]);

  const paginationFooterVisible =
    taJoinATripPaginationActivated && totalTravelersCount > INDEX_INCREMENT;
  return (
    <Box className={Styles["search-list-wrapper"]}>
      <Box
        className={Styles["search-list-header"]}
        styles={(theme) => ({
          backgroundColor:
            theme.theme.siteVariables.colorScheme?.default.background,
        })}
      >
        {selectedUser && getSelectedUserLabel(selectedUser)}
        <Flex
          space="between"
          column={isMobileOrTablet}
          gap={isMobileOrTablet ? "gap.small" : undefined}
          className={[
            Styles["search-favorite-container"],
            isMobileOrTablet && Styles.mobile,
          ].join(" ")}
          styles={(theme) => ({
            backgroundColor:
              theme.theme.siteVariables.colorScheme?.default.background,
          })}
        >
          <Text
            content={t("shareJoin.selectBookerLabel")}
            className={[
              Styles[shareJoinTextMajorClassName],
              isMobileOrTablet && Styles.mobile,
            ].join(" ")}
            styles={{ alignContent: "center" }}
          />
          <Checkbox
            checked={searchFavorite}
            label={t("shareJoin.showFavoritesLabel")}
            toggle
            onChange={() => setSearchFavorite(!searchFavorite)}
            data-testid="toggle-favorite-checkbox"
            aria-label="toggle favorite checkbox"
            styles={(theme) => ({
              paddingLeft: 0,
              color:
                searchFavorite &&
                theme.theme.siteVariables.colorScheme?.default.foreground2,
              fontSize: isMobileOrTablet ? "13px" : "14px",
              lineHeight: isMobileOrTablet ? "18px" : "20px",
              fontWeight: 400,
            })}
          />
        </Flex>
        {isMobileOrTablet && (
          <Flex className={Styles["mobile-input-label"]}>
            <Text
              content={t("shareJoin.searchInputMobileLabel")}
              className={[
                Styles[shareJoinTextMinorClassName],
                isMobileOrTablet && Styles.mobile,
              ].join(" ")}
              styles={(theme) => ({
                color:
                  theme.theme.siteVariables.colorScheme?.default.foreground2,
              })}
            />
          </Flex>
        )}
        <SearchInput
          value={inputValue}
          onChange={handleInputChange}
          isMobileOrTablet={isMobileOrTablet}
        />
      </Box>
      {isMobileOrTablet && (
        <Box
          className={Styles["list-divider"]}
          styles={(theme) => ({
            color: theme.theme.siteVariables.colorScheme?.default.border3,
          })}
        />
      )}
      {!isLoaded && (
        <Loader style={{ marginTop: isMobileOrTablet ? "50%" : "20%" }} />
      )}
      {travelersList && (
        <Flex
          column
          className={[
            Styles["list-container"],
            isMobileOrTablet && Styles.mobile,
            totalTravelersCount > INDEX_INCREMENT && Styles.pagination,
          ].join(" ")}
        >
          <List
            items={travelersList}
            data-testid="book-for-colleague-list"
            aria-label="book for colleague list"
            styles={{ marginRight: 0 }}
          />
          {paginationFooterVisible && paginationFooter()}
        </Flex>
      )}
      {searchButton(subEntityId.segmentType, !selectedUser)}
    </Box>
  );
}

export default TravelersList;
