import HandleTravelCytricRedirection from "../../../utils/HandleTravelCytricRedirection";

export default function BookingCytricRedirection(
  subEntityId: string,
  history: any,
  t: any,
  dispatch: any
) {
  const breadcrumbData = subEntityId.includes("bookFromTripLogistics")
    ? [
        {
          label: "upcoming_trips_dashboard.tripDetails.breadcrumb.previousPage",
          url: "/travel",
          translation: true,
        },
        {
          label: "upcoming_trips_dashboard.breadcrumb.planATrip",
          url: "",
          translation: true,
        },
      ]
    : [
        {
          label: "upcoming_trips_dashboard.tripDetails.breadcrumb.previousPage",
          url: "/travel",
          translation: true,
        },
        {
          label: "upcoming_trips_dashboard.breadcrumb.joinATrip",
          url: "",
          translation: true,
        },
      ];

  HandleTravelCytricRedirection({ breadcrumbData, t, dispatch });

  history.push(`/cytricweb?context=${subEntityId}&type=travel`);
}
