/* eslint-disable react/require-default-props */
import { telemetryService } from "@amadeus-cytric/cytric-teams-react-common-library";
import {
  cardsContainerBehavior,
  Flex,
  Grid,
  Text,
} from "@fluentui/react-northstar";
import { app } from "@microsoft/teams-js";
import { Feature } from "@paralleldrive/react-feature-toggles";
import React, { useEffect, useState } from "react";
import { isMobileOnly } from "react-device-detect";
import { useTranslation } from "react-i18next";
import GetTaskResponseComponent from "../../../common/components/alerts/GetTaskResponseComponent";
import GetTaskResponseStorage from "../../../common/components/alerts/GetTaskResponseStorage";
import { selectFeatureToggle } from "../../../store/feature-toggles/FeatureTogglesSelector";
import { useAppSelector } from "../../../store/StoreHooks";
import { selectTripsV1SortedByDepartureDate } from "../../../store/trips-v1/selectors/sortTripsSelector";
import { FeatureToggleDefinition } from "../../../utils/constants";
import { getDevice } from "../../../utils/device.utils";
import { KeepSessionActions } from "../../../utils/KeepSessionFunctions";
import ActivityClass from "../../../utils/KeepUserActivityContext";
import IResponseProps from "../cancel/CancelTripModel";
import { CancelPopUpProvider } from "../cancel/context/CancelPopUpContext";
import CardBookTripButtons from "./CardBookTripButtons/CardBookTripButtons";
import { SegmentType } from "./CardBookTripButtons/model/Permissions";
import Header from "./Header/Header";
import PastTripsComponent from "./PastTrips/PastTripsComponent";
import SideBarCard from "./SideBarCard/SideBarCard";
import TravelArranger from "./TravelArrenger/TravelArranger";
import TripCardV1 from "./TripCardV1/TripCardV1";
import Styles from "./UpcomingTrips.module.scss";

interface IDashboard {
  permissions?: SegmentType[];
}

function UpcomingTrips({ permissions }: IDashboard) {
  const [themeString, setThemeString] = useState("light");
  const [pastTripsLength, setPastLength] = useState();
  const [messageResponse, setMessageResponse] = useState<IResponseProps>({
    alertText: null,
    responseStatus: null,
    id: null,
  });
  const tripsV1 = useAppSelector(selectTripsV1SortedByDepartureDate);
  const { t } = useTranslation("translation", {
    keyPrefix: "upcoming_trips_dashboard.upcomingTrips",
  });
  const { t: t2 } = useTranslation();
  const titleStyle = Styles["list-title"];

  const device = getDevice();

  const isSidebarHidden = useAppSelector(
    (state) => state.tripsSummary.isSidebarHidden
  );

  const userActivity = new ActivityClass();

  useEffect(() => {
    app.initialize().then(() => {
      app
        .getContext()
        .then((ctx: app.Context) =>
          setThemeString(
            ctx.app?.theme === "default" ? "light" : ctx.app?.theme
          )
        );
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tripsV1 && pastTripsLength) {
      telemetryService.trackPageView({
        name: "travel-dashboard",
        properties: {
          numOfTrips: tripsV1.length,
          numOfPastTrips: pastTripsLength,
        },
      });
    }
    const container = document.querySelector("#main_container_tripDashboard");
    if (container) {
      container.scrollTop = userActivity.get(
        KeepSessionActions.DASHBOARD_SCROLL,
        0
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pastTripsLength, tripsV1]);

  // Trips Card API version displayed based on Feature Toggle
  const tripCards = () =>
    tripsV1.map((trip: any, index: number) => (
      <TripCardV1
        trip={trip}
        key={trip.id}
        index={index}
        totalitems={tripsV1.length}
      />
    ));

  const sideBarFeatureToogle = useAppSelector((state: any) =>
    selectFeatureToggle(state, FeatureToggleDefinition.travelSidebar.id)
  );

  const screenReaderContent = `. ${tripsV1.length} ${
    tripsV1.length > 1 ? t("items") : t("item")
  }`;

  function travelArrangerDashboardComponent() {
    const travelersFlow = permissions?.find(
      (item) => item.type === "TRAVELERS"
    );
    let travelersFlowTAD = null;
    if (travelersFlow) {
      travelersFlowTAD = travelersFlow.bookingTargets?.find(
        (target) => target.type === "TAD"
      );
    }
    if (travelersFlowTAD && travelersFlowTAD.activated) {
      return (
        <Feature
          activeComponent={() => TravelArranger(themeString, isSidebarHidden)}
          name={FeatureToggleDefinition.travelArranger.id}
        />
      );
    }
    return null;
  }

  useEffect(() => {
    GetTaskResponseStorage(t2, setMessageResponse);
  }, [t2]);

  const handleScroll = (event: any) => {
    const { scrollTop } = event.currentTarget;

    userActivity.update(KeepSessionActions.DASHBOARD_SCROLL, scrollTop);
  };

  return (
    <div className={Styles.main_container_travelTab}>
      <div
        className={Styles.main_container}
        data-testid="trip-dashboard-main"
        id="main_container_tripDashboard"
        onScroll={handleScroll}
        style={
          isMobileOnly ? { display: !isSidebarHidden ? "inline" : "none" } : {}
        }
      >
        <Flex
          className={
            device === "mobile" && messageResponse.alertText !== null
              ? Styles.cancelAlertErrorMobile
              : ""
          }
        >
          {GetTaskResponseComponent(messageResponse)}
          <div
            className={
              device === "mobile" ? "" : Styles.cancelAlertErrorDesktop
            }
          />
        </Flex>
        <Flex column gap="gap.medium">
          <Header />
          {travelArrangerDashboardComponent()}
        </Flex>
        <div className={Styles.main_container_trips}>
          <div role="main" aria-label="Upcoming trips">
            <h2>
              <Text
                as="div"
                className={titleStyle}
                styles={(theme) => ({
                  color: theme.theme.siteVariables.upcomingTrips?.title,
                })}
              >
                {t("title")}
                <Text
                  className={Styles.screenReaderOnly}
                  content={screenReaderContent}
                />
              </Text>
            </h2>

            <Grid
              accessibility={cardsContainerBehavior}
              className={`${
                !isSidebarHidden
                  ? Styles.grid_container_sidebar_expanded
                  : Styles.grid_container
              }`}
            >
              {CardBookTripButtons(permissions)}
              <CancelPopUpProvider>{tripCards()}</CancelPopUpProvider>
            </Grid>
          </div>

          <PastTripsComponent
            setPastLength={setPastLength}
            isSidebarHidden={isSidebarHidden}
            themeString={themeString}
          />
        </div>
      </div>

      {sideBarFeatureToogle?.isActive && (
        <SideBarCard themeName={themeString} />
      )}
    </div>
  );
}

export default UpcomingTrips;
