import {
  statusIcon,
  statusIconClass,
} from "@amadeus-cytric/cytric-teams-react-common-library";
import stylesUserStatus from "@amadeus-cytric/cytric-teams-react-common-library/dist/components/user-status/UserStatus.module.scss";
import {
  AirplaneRegular,
  BedRegular,
  VehicleCarRegular,
  VehicleSubwayRegular,
} from "@fluentui/react-icons";
import { Avatar, Flex, Text } from "@fluentui/react-northstar";
import { Dispatch, SetStateAction } from "react";
import { getStatusTitle } from "../share-my-ride/utils/shareMyRideUtils";
import Styles from "./EventAttendees.module.scss";

export const dontClose = (e: any) => {
  if (e.keyCode === 13) {
    e.preventDefault();
  }
};

export const getUser = (user: any, index: any) => {
  const { displayName, image, status } = user.props.dataContacts;
  return (
    <Flex gap="gap.small" vAlign="center">
      <Flex.Item data-testid={`Attendee-row-${index}`}>
        <Avatar
          name={displayName}
          image={image}
          status={
            user.status !== null && {
              styles: (theme) => ({
                backgroundColor:
                  theme.theme.siteVariables.widgetContainer?.widget,
                color:
                  theme.theme.siteVariables?.colorScheme?.default.background,
              }),
              icon: statusIcon(status.availability),
              title: getStatusTitle(status.activity),
              className: `${stylesUserStatus.statusIndicator} ${
                statusIconClass(status.availability).icon.class
              }`,
            }
          }
          aria-hidden
        />
      </Flex.Item>
      <Flex.Item>
        <Flex column>
          <Text
            content={displayName}
            title={displayName}
            styles={(theme) => ({
              color: theme.theme.siteVariables.colorScheme?.default.foreground,
            })}
            aria-hidden
          />
        </Flex>
      </Flex.Item>
    </Flex>
  );
};

export const imageLabel = (type: string, shared: boolean, t: any) => {
  const bookInfo = shared
    ? t("events.eventSharetrip.tripSummary.tripContacts.booked")
    : t("events.eventSharetrip.tripSummary.tripContacts.notBooked");
  return `${type} ${bookInfo}`;
};

export const getTravelIcon = (
  type: string,
  hasShared: boolean,
  IconComponent: any,
  testId: string,
  ICON_STYLE: any,
  ICON_STYLE_DISABLED: any,
  t: any
) => (
  <Flex
    styles={hasShared ? ICON_STYLE : ICON_STYLE_DISABLED}
    className={Styles.iconMedium}
    aria-label={imageLabel(type, hasShared, t)}
    data-testid={testId}
    role="img"
  >
    <IconComponent />
  </Flex>
);

export const getFlightIcon = (
  hasFlight: boolean,
  ICON_STYLE: any,
  ICON_STYLE_DISABLED: any,
  t: any
) =>
  getTravelIcon(
    t("upcoming_trips_dashboard.upcomingTrips.newBook.Flight"),
    hasFlight,
    AirplaneRegular,
    "travel-icon-flight",
    ICON_STYLE,
    ICON_STYLE_DISABLED,
    t
  );

export const getRailIcon = (
  hasRail: boolean,
  ICON_STYLE: any,
  ICON_STYLE_DISABLED: any,
  t: any
) =>
  getTravelIcon(
    t("upcoming_trips_dashboard.upcomingTrips.newBook.Rail"),
    hasRail,
    VehicleSubwayRegular,
    "travel-icon-rail",
    ICON_STYLE,
    ICON_STYLE_DISABLED,
    t
  );

export const getHotelIcon = (
  hasHotel: boolean,
  ICON_STYLE: any,
  ICON_STYLE_DISABLED: any,
  t: any
) =>
  getTravelIcon(
    t("upcoming_trips_dashboard.upcomingTrips.newBook.Hotel"),
    hasHotel,
    BedRegular,
    "travel-icon-hotel",
    ICON_STYLE,
    ICON_STYLE_DISABLED,
    t
  );

export const getCarIcon = (
  hasCar: boolean,
  ICON_STYLE: any,
  ICON_STYLE_DISABLED: any,
  t: any
) =>
  getTravelIcon(
    t("upcoming_trips_dashboard.upcomingTrips.newBook.CarTransfer"),
    hasCar,
    VehicleCarRegular,
    "travel-icon-car",
    ICON_STYLE,
    ICON_STYLE_DISABLED,
    t
  );

export const getRows = (
  attendees: any,
  ICON_STYLE: any,
  ICON_STYLE_DISABLED: any,
  t: any
) =>
  attendees.map((owner: any, index: any) => ({
    key: `${index}-row`,
    items: [
      {
        content: getUser(owner, index),
        key: `${index}-userName`,
      },
      {
        content: (
          <Text
            content={
              owner.props.dataContacts.dates !== ""
                ? owner.props.dataContacts.dates
                : t("events.eventSharetrip.tripSummary.tripContacts.noData")
            }
            title={owner.props.dataContacts.dates}
            styles={(theme) => ({
              color: theme.theme.siteVariables.colorScheme?.default.foreground,
            })}
            aria-hidden
          />
        ),
        key: `${index}-tripDates`,
      },
      {
        content: (
          <Flex
            data-testid="shared-bookings-icons"
            styles={{ justifyContent: "space-between", width: "105px" }}
          >
            {getFlightIcon(
              owner.props.dataContacts.hasSharedFlight,
              ICON_STYLE,
              ICON_STYLE_DISABLED,
              t
            )}
            {getRailIcon(
              owner.props.dataContacts.hasSharedRail,
              ICON_STYLE,
              ICON_STYLE_DISABLED,
              t
            )}
            {getHotelIcon(
              owner.props.dataContacts.hasSharedHotel,
              ICON_STYLE,
              ICON_STYLE_DISABLED,
              t
            )}
            {getCarIcon(
              owner.props.dataContacts.hasSharedCar,
              ICON_STYLE,
              ICON_STYLE_DISABLED,
              t
            )}
          </Flex>
        ),
        key: `${index}-bookings`,
      },
    ],
    className: Styles.tableColumns,
  }));

export const getFilteredSearch = (items: any[], query: string) =>
  items.filter(
    (item) =>
      item.props.dataContacts?.displayName &&
      typeof query === "string" &&
      item.props.dataContacts.displayName
        .toLowerCase()
        .includes(query.toLowerCase())
  );

export const getDropdownItems = (items: any[], query: string) =>
  getFilteredSearch(items, query)?.map((item) => ({
    key: item.props.dataContacts?.id,
    header: item.props.dataContacts?.displayName,
    image: { src: item.props.dataContacts?.image, avatar: true },
    content: item.props.dataContacts?.displayName,
  }));

export const handleClear = (
  setQuery: Dispatch<SetStateAction<string>>,
  setUpdatedAttendeesObject: Dispatch<SetStateAction<any>>,
  initialAttendeesObject: React.MutableRefObject<any>
) => {
  setQuery("");
  setUpdatedAttendeesObject(initialAttendeesObject.current);
};

export const handleOnChange = (
  _e: any,
  param: any,
  setQuery: Dispatch<SetStateAction<string>>,
  setUpdatedAttendeesObject: Dispatch<SetStateAction<any>>,
  initialAttendeesObject: React.MutableRefObject<any>
): void => {
  if (_e === null) {
    setQuery(param.value?.content || "");
    setUpdatedAttendeesObject((prevContacts: any[]) =>
      getFilteredSearch(prevContacts, param.value?.content)
    );
  } else {
    setQuery("");
    setUpdatedAttendeesObject(initialAttendeesObject.current);
  }
};

export const handleSearchQueryChange = (
  _e: any,
  { searchQuery }: any,
  setQuery: Dispatch<SetStateAction<string>>,
  handleClear2: () => void
) => {
  setQuery(searchQuery || "");
  if (searchQuery === "") {
    handleClear2();
  }
};
