/* eslint-disable import/no-named-as-default */
import {
  PanelRightContract20Regular,
  PanelRightExpand20Regular,
} from "@fluentui/react-icons";
import { Button, Divider, Flex, Text } from "@fluentui/react-northstar";
import { app } from "@microsoft/teams-js";
import React, { useEffect, useState } from "react";
import { isMobileOnly } from "react-device-detect";
import { useTranslation } from "react-i18next";
import Store from "../../../../store/Store";
import { useAppDispatch, useAppSelector } from "../../../../store/StoreHooks";
import { selectReverseCollaborators } from "../../../../store/collaborators/reverse-collaborators/ReverseCollaboratorSelector";
import { getReverseCollaborators } from "../../../../store/collaborators/reverse-collaborators/ReverseCollaboratorsAction";
import { selectFeatureToggle } from "../../../../store/feature-toggles/FeatureTogglesSelector";
import { tripsSummaryActions } from "../../../../store/upcoming-trips-dashboard/trips-summary-slice";
import CheckFeatureToggle from "../../../../utils/CheckFeatureToggle";
import { KeepSessionActions } from "../../../../utils/KeepSessionFunctions";
import ActivityClass from "../../../../utils/KeepUserActivityContext";
import { FeatureToggleDefinition } from "../../../../utils/constants";
import ReverseCollaboratorsTable from "../../../collaborators/reverse-collaborators/ReverseCollaborators";
import EventSection from "../../Events/EventSection";
import HandleAppPermissions from "../../Events/HandleAppPermissions";
import Styles from "./SideBarCard.module.scss";

function EmptyComponent() {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
}

function SideBarCard({
  // toggleHiddenTrips,
  themeName,
}: {
  // toggleHiddenTrips: any;
  themeName: string;
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const isSidebarHiddenRedux = useAppSelector(
    (state) => !state.tripsSummary.isSidebarHidden
  );
  const reverseCollaborators = useAppSelector(selectReverseCollaborators);
  const featureToogle = useAppSelector((state: any) =>
    selectFeatureToggle(
      state,
      FeatureToggleDefinition.reverseCollaboratorsTable.id
    )
  );
  const featureToogleEventPermission = useAppSelector((state: any) =>
    selectFeatureToggle(state, FeatureToggleDefinition.eventsPermissionCheck.id)
  );
  const isBlackListed = CheckFeatureToggle(
    FeatureToggleDefinition.buttonEventTemplate.id
  );

  const [themeString, setThemeString] = useState<any>();
  const [isAppPermissionGranted, setIsAppPermissionGranted] =
    useState<boolean>(false);

  useEffect(() => {
    app.getContext().then((ctx: app.Context) => setThemeString(ctx.app.theme));
  }, []);

  useEffect(() => {
    const checkAppPermission = async () => {
      const result = await HandleAppPermissions();
      setIsAppPermissionGranted(result);
    };

    checkAppPermission();
  }, []);

  useEffect(() => {
    if (isMobileOnly) {
      dispatch(tripsSummaryActions.setIsSidebarHidden(false));
    }

    Store.dispatch(getReverseCollaborators());
  }, [dispatch]);

  const getSidebarState = () => {
    const userActivity = new ActivityClass();
    const userActivityValue = userActivity.get(
      KeepSessionActions.DASHBOARD_SIDEBAR
    );
    if (userActivityValue) {
      return userActivityValue;
    }
    return isSidebarHiddenRedux;
  };

  const isSidebarHidden = getSidebarState();

  const hideShowFunction = (): void => {
    const userActivity = new ActivityClass();
    userActivity.update(KeepSessionActions.DASHBOARD_SIDEBAR, !isSidebarHidden);

    dispatch(tripsSummaryActions.toggleIsSidebarHidden());
  };

  const eventSectionComponent = () => (
    <EventSection themeString={themeString} />
  );

  const buttonStyles = (theme: any) => ({
    color: theme.theme.siteVariables.colorScheme?.brand.foreground2,
  });

  const checkFeatureEventPermission = () => {
    if (featureToogleEventPermission && isBlackListed === false) {
      if (isAppPermissionGranted) {
        return eventSectionComponent;
      }
    }
    return EmptyComponent;
  };

  return (
    <>
      <Button
        className={`${Styles.buttonSideBar} ${
          isSidebarHidden
            ? Styles.btnSideBarPositionHidden
            : Styles.btnSideBarPositionDisplay
        }`}
        data-testid="button-show-hidde-bar"
        onClick={hideShowFunction}
        flat
        tinted
        styles={(theme) => ({
          borderColor: theme.theme.siteVariables?.colorScheme?.default.border,
        })}
      >
        {isSidebarHidden ? (
          <Flex styles={buttonStyles}>
            <PanelRightExpand20Regular />
          </Flex>
        ) : (
          <Flex styles={buttonStyles}>
            <PanelRightContract20Regular />
          </Flex>
        )}
        {isSidebarHidden ? (
          <Text styles={buttonStyles}>
            {t("travelSidebar.buttons.showPanel")}
          </Text>
        ) : (
          <Text styles={buttonStyles}>
            {t("travelSidebar.buttons.hidePanel")}
          </Text>
        )}
      </Button>

      <Flex
        hAlign="start"
        data-testid="trip-sidebar-container"
        className={`${themeName === "light" ? Styles.white : Styles.dark} ${
          Styles.cardSideBarContainer
        } ${isSidebarHidden ? Styles.sideBarHidden : Styles.sideBarDisplay}`}
        styles={(theme: any) => ({
          background: theme.theme.siteVariables.tripCard?.background,
          borderColor: theme.theme.siteVariables?.colorScheme?.default.border,
        })}
      >
        <Flex.Item>{checkFeatureEventPermission()}</Flex.Item>
        <Divider color="default" className={Styles.divider} />

        {featureToogle?.isActive && (
          <Flex.Item>
            <ReverseCollaboratorsTable
              collaborators={reverseCollaborators}
              themeName={themeName}
            />
          </Flex.Item>
        )}

        <Divider color="default" className={Styles.bottomDivider} />
      </Flex>
    </>
  );
}

export default SideBarCard;
