import {
  Http,
  telemetryService,
} from "@amadeus-cytric/cytric-teams-react-common-library";
import { Flex, Loader } from "@fluentui/react-northstar";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import GetTaskResponseComponent from "../../../common/components/alerts/GetTaskResponseComponent";
import GetTaskResponseStorage from "../../../common/components/alerts/GetTaskResponseStorage";
import Pages from "../../../common/models/enums/Pages";
import FlowsService from "../../../services/flows/flows.service";
import { isLoggedInSelector } from "../../../store/authentication/AuthenticationSelector";
import { resetCloseCollaborators } from "../../../store/collaborators/close-collaborators/CloseCollaboratorsSlice";
import { selectFeatureToggle } from "../../../store/feature-toggles/FeatureTogglesSelector";
import { flowsPermissionsActions } from "../../../store/flows/FlowsPermissionsSlice";
import Store from "../../../store/Store";
import { useAppDispatch, useAppSelector } from "../../../store/StoreHooks";
import { updateTravelTripId } from "../../../store/travel-trip-id/TravelTripIdSlice";
import { tripDetailV1Actions } from "../../../store/trip-details-v1/TripDetailsV1Slice";
import { tripDetailActions } from "../../../store/upcoming-trips-dashboard/trip-detail-slice";
import { FeatureToggleDefinition } from "../../../utils/constants";
import HostSettings from "../../../utils/host.settings";
import returnKeepSession, {
  addSessionValue,
  getKeepSessionValue,
  KeepSessionActions,
} from "../../../utils/KeepSessionFunctions";
import ActivityClass from "../../../utils/KeepUserActivityContext";
import Coachmarks from "../../coachmarks/Coachmarks";
import CancelContext from "../cancel/CancelContext";
import IResponseProps from "../cancel/CancelTripModel";
import Error from "../error/Error";
import flowsPermissionFixer from "../UpcomingTrips/CardBookTripButtons/PunchOutLogic";
import navigateTo from "../utils/NavigateTo";
import { composeTripName } from "../utils/trip-utils";
import BreadcrumbV1 from "./Breadcrumb/BreadcrumbV1";
import HeaderV1 from "./Header/HeaderV1";
import { IUpcomingTripDetailProps } from "./UpcomingTripDetail.model";
import Styles from "./UpcomingTripDetail.module.scss";
import WidgetsGrid from "./WidgetsGrid/WidgetsGrid";

function UpcomingTripDetail() {
  const dispatch = useAppDispatch();
  const { tripId } = useParams<IUpcomingTripDetailProps>();
  const [content, setContent] = useState<any>(null);
  const [data, setData] = useState<any>(null);
  const [dataV1, setDataV1] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [messageResponse, setMessageResponse] = useState<IResponseProps>({
    alertText: null,
    responseStatus: null,
    id: null,
  });
  const { t } = useTranslation();
  const history = useHistory();
  const param = history.location.state;
  const previousSessionObject = returnKeepSession();
  let isPastTrip: any =
    previousSessionObject && previousSessionObject.value
      ? previousSessionObject.value[0].pastTrip
      : param === "past";

  const isLoggedIn: boolean = useAppSelector((state: any) =>
    isLoggedInSelector(state)
  );
  const tripDetailsErrorCode: number = useAppSelector(
    (state: any) => state.tripDetailsErrorCode
  );
  const featureTogglePunchOuts = useAppSelector((state: any) =>
    selectFeatureToggle(state, FeatureToggleDefinition.DashboardPunchOut.id)
  );

  const [cancelPopup, setCancelPopup] = useState(false);
  const cancelValue = useMemo(
    () => ({ cancelPopup, setCancelPopup }),
    [cancelPopup, setCancelPopup]
  );

  useEffect(() => {
    const originalHtmlOverflow = document.documentElement.style.overflow;
    document.documentElement.style.overflow = "hidden";

    return () => {
      document.documentElement.style.overflow = originalHtmlOverflow;
    };
  }, []);

  const newKeepSession = (trip: string) => {
    const keepSessionObject = returnKeepSession();
    let checkSavedTab = null;
    if (keepSessionObject) {
      checkSavedTab =
        keepSessionObject.page +
        getKeepSessionValue(keepSessionObject, "tripId");

      if (checkSavedTab === null || checkSavedTab !== `/trip:${trip}`) {
        keepSessionObject.page = Pages.TripDetail;
        keepSessionObject.value = addSessionValue(
          keepSessionObject,
          "tripId",
          trip,
          isPastTrip
        );
        const now = new Date();
        keepSessionObject.expiration = JSON.stringify(
          now.getTime() + parseInt(HostSettings.getKeepSessionTimeout, 10)
        );
        localStorage.setItem(
          "KeepSessionCache",
          JSON.stringify(keepSessionObject)
        );
      } else if (checkSavedTab === `/trip:${trip}`) {
        const getTime = keepSessionObject.expiration;
        const now = new Date();
        if (getTime && now.getTime() > JSON.parse(getTime)) {
          localStorage.removeItem("KeepSessionCache");
          navigateTo("travel");
        } else {
          isPastTrip = keepSessionObject.value[0].pastTrip;
          keepSessionObject.expiration = JSON.stringify(
            now.getTime() + parseInt(HostSettings.getKeepSessionTimeout, 10)
          );
          localStorage.setItem(
            "KeepSessionCache",
            JSON.stringify(keepSessionObject)
          );
        }
      }
    } else {
      const now = new Date();
      const newKeepSessionObject = {
        page: Pages.TripDetail,
        value: [{ id: "tripId", value: trip, pastTrip: isPastTrip }],
        expiration: JSON.stringify(
          now.getTime() + parseInt(HostSettings.getKeepSessionTimeout, 10)
        ),
      };
      localStorage.setItem(
        "KeepSessionCache",
        JSON.stringify(newKeepSessionObject)
      );
    }
  };

  const keepSession = (trip: string) => {
    newKeepSession(trip);
  };

  const handleTripV1 = () => {
    if (tripId) {
      const trip = tripId.replace(":", "");
      keepSession(trip);
      const getTripDetailsV1 = async () => {
        const responsePermissions = await FlowsService.getFlows();
        if (responsePermissions) {
          const flowPermissions = flowsPermissionFixer(
            responsePermissions,
            featureTogglePunchOuts
          );
          dispatch(flowsPermissionsActions.setPermissions(flowPermissions));
        }

        const url = `${HostSettings.getBaseAPI}${HostSettings.getTripListV1}${trip}`;

        setIsLoading(true);
        let result;
        try {
          const response = await Http.get(url);
          result = await response.data;
        } catch (error: any) {
          dispatch(tripDetailV1Actions.setTripError(error));
        }
        return result;
      };
      getTripDetailsV1().then((results) => {
        dispatch(tripDetailV1Actions.setTrip(results));
        setDataV1(results);
        setIsLoading(false);
      });
    }
  };

  const handleTripV0 = () => {
    let trip = "";
    if (tripId) {
      trip = tripId.replace(":", "");
      dispatch(updateTravelTripId({ tripId: trip }));
    }
    keepSession(trip);
    const getTripDetails = async () => {
      const url = `${HostSettings.getBaseAPI}${HostSettings.getTripList}${trip}`;
      setIsLoading(true);
      let result;
      try {
        const response = await Http.get(url);
        result = (await response.data.data) || (await response.data);
        dispatch(tripDetailActions.setTrip(result));
      } catch (error: any) {
        localStorage.removeItem("KeepSessionCache");
        if (error.request?.status === 429 || tripDetailsErrorCode === 429) {
          setContent(<Error type="many" />);
        } else {
          navigateTo("travel");
        }
      }
      return result;
    };
    getTripDetails().then((results) => {
      if (results) {
        setData(results);
      }
    });
  };

  useEffect(() => {
    if (isLoggedIn) {
      telemetryService.trackPageView({ name: "travel-tripView" });
    }
    Store.dispatch(resetCloseCollaborators());
    handleTripV1();
    handleTripV0();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    GetTaskResponseStorage(t, setMessageResponse);
  }, [t]);

  const handleScroll = (event: any) => {
    const activity = new ActivityClass();
    const { scrollTop } = event.currentTarget;

    activity.update(KeepSessionActions.DETAILS_SCROLL, scrollTop);
  };

  useEffect(() => {
    const component = (
      <div role="navigation" aria-label="breadscrumbs">
        <BreadcrumbV1 pastTrip={isPastTrip} />
        <div
          className={Styles.main_container}
          id="main_container_tripDetails"
          onScroll={handleScroll}
          role="main"
          text-label={composeTripName(data, t("tripDetails.tripTo"))}
          aria-label={composeTripName(data, t("tripDetails.tripTo"))}
        >
          <Flex>{GetTaskResponseComponent(messageResponse)}</Flex>
          <HeaderV1 />
          <WidgetsGrid trip={data} pastTrip={isPastTrip} />
        </div>
      </div>
    );
    if (dataV1) {
      setIsLoading(false);

      setContent(component);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataV1]);

  if (isLoading) {
    return <Loader style={{ margin: 100 }} data-testid="tab-loader" />;
  }

  return (
    <CancelContext.Provider value={cancelValue}>
      {dataV1 && (
        <>
          {content}
          <Coachmarks />
        </>
      )}
    </CancelContext.Provider>
  );
}

export default UpcomingTripDetail;
